
$gap: 4px;

$primaryColour: #3f7129;
$secondaryColour: #af8316;

$backgroundColour: white;

.input {
        margin-top: 4px;
        margin-bottom: 4px;
}
.plantimage {
    max-width: 512px;
    max-height: 512px;
    border: black 1px solid;
}

.button {
    margin: 4px;
}

.mixedtitle{
    margin-left: 8px;
    margin-right: 8px;
    width: 100%;
}

CardContent {
    width: 100%;
}