
.root {
    .hidden { 
        display: none;
        border: 1px solid red;
    }
    .shown {
        display: inline;
        border: 1px solid black;
        width: 100%;
    }
}